import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import clone from 'lodash/clone';
import React, { memo, useCallback } from 'react';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KLabel from '../Label';
import { KCheckboxProps } from '../types';

const KCheckbox = (props: KCheckboxProps) => {
  const {
    activeColor,
    typo,
    checked,
    label,
    disabled,
    onChange,
    onAfterChange,
    withMinus,
    size = 24,
    color,
    ...rest
  } = props;

  const toggle = useCallback(() => {
    const currentChecked = clone(checked);
    onChange?.(!currentChecked);
    onAfterChange?.(!currentChecked);
  }, [checked, onChange, onAfterChange]);

  const renderCheckbox = useCallback(() => {
    if (checked) {
      const Icon = withMinus ? IndeterminateCheckBoxOutlined : CheckBoxOutlined;
      return (
        <Icon
          sx={{
            width: size,
            height: size,
            color: activeColor,
            opacity: disabled ? 0.3 : 1
          }}
        />
      );
    } else {
      return (
        <CheckBoxOutlineBlankOutlined
          sx={{
            width: size,
            height: size,
            opacity: disabled ? 0.3 : 1,
            color: color
          }}
        />
      );
    }
  }, [activeColor, checked, color, disabled, size, withMinus]);

  const Wrapper = disabled ? KContainer.View : KContainer.Touchable;

  return (
    <Wrapper
      onPress={toggle}
      row
      alignItems
      avoidParentPress
      width="fit-content"
      noHover
      {...rest}
    >
      {renderCheckbox()}

      <KLabel.Text typo={typo} marginL="0.5rem" color={color}>
        {label}
      </KLabel.Text>
    </Wrapper>
  );
};

KCheckbox.defaultProps = {
  activeColor: KColors.primary.normal,
  typo: 'TextNmNormal'
};

export default memo(KCheckbox);
