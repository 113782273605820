import { E_RACE_MODES, WORD_STATUSES } from '@constants/gameConfigs';
import { IRoomDto, IWord } from '@dto';
import { useGameContext } from 'context/GameContext';
import { useAppEvent, useStudentInGameInfo } from 'hooks';
import { useBoolean } from 'hooks/core/useBoolean';
import { useRemainingTimeChanged } from 'hooks/game/useRemainingTimeChanged';
import useInGameImageSize from 'hooks/room/useInGameImageSize';
import { isEmpty, isEqual } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import trans from 'translation';
import {
  KContainer,
  KLabel,
  KColors,
  KButton,
  KInput,
  KSpacingValue
} from 'uikit';
import { UIUtils } from 'utils';

import InGameRemainingTime from '../InGame.RemainingTime';
import TimesUp from '../TimesUp';

const StudentJumble = () => {
  const time = useRemainingTimeChanged();

  const SIZE = useInGameImageSize();

  const { raceMode, onUpdateWordOrder } = useGameContext();

  const { myJumbleRunningWords, myTeam } = useStudentInGameInfo();

  const { setTrue, setFalse, value: submitButtonEnabled } = useBoolean(true);

  useEffect(() => {
    const words = myTeam?.words ?? [];
    const isFullFilled = words.every(
      i => i.score !== null && i.score !== undefined
    );
    const isNotRepeatedOrder = isEqual(
      words.map(i => i.order).sort(),
      words.map((_, index) => index + 1)
    );
    if (isFullFilled && isNotRepeatedOrder && submitButtonEnabled) {
      setFalse();
    } else if ((!isFullFilled || !isNotRepeatedOrder) && !submitButtonEnabled) {
      setTrue();
    }
  }, [myTeam?.words, setFalse, setTrue, submitButtonEnabled]);

  const onSocketReceivedWarning = useCallback(
    (event: any) => {
      const data: IRoomDto = event.detail;
      const { teams } = data || {};
      const _myTeam = teams?.find(i => i.id === myTeam?.id);
      const _words = _myTeam?.words ?? [];
      const _groupedByOrder = _words.reduce<Record<string, IWord[]>>(
        (prev, curr) => {
          if (!curr.order) {
            return prev;
          }
          if (!prev[curr.order]) {
            prev[curr.order] = [curr];
          } else {
            prev[curr.order].push(curr);
          }
          return prev;
        },
        {}
      );
      const _duplicatedWords = Object.values(_groupedByOrder).filter(
        i => i.length > 1
      );

      if (!isEmpty(_duplicatedWords)) {
        const orders = _duplicatedWords.map(_dWords => {
          return _dWords[0]?.order;
          // const o = _dWords[0]?.order;
          // return `Order ${o} has been selected twice! Please change your answer`;
          // const m = _dWords.map(w => w.texts[0]).join(', ');
          // return `${m} have the same order ${_dWords[0]?.order}`;
        });
        const msg = `Order ${orders.join(
          ', '
        )} has been selected twice! Please change your answer`;
        UIUtils.snackBar.open({
          message: msg,
          status: 'warning'
        });
      }
    },
    [myTeam?.id]
  );

  useAppEvent('ON_SOCKET_RECEIVED_WARNING', onSocketReceivedWarning as any);

  const [orders, setOrders] = useState<string[]>([]);

  const onSubmit = useCallback(() => {
    onUpdateWordOrder(
      myJumbleRunningWords.map((i, index) => {
        return {
          ...i,
          order: Number(orders[index])
        };
      })
    );
  }, [myJumbleRunningWords, onUpdateWordOrder, orders]);

  const options = useMemo(() => {
    const arr = (myTeam?.words ?? []).map((_, index) => index);
    return myJumbleRunningWords.map(word => {
      return {
        options: arr.map(i => ({
          id: i + 1,
          title: `${i + 1}`
        })),
        text: word.texts
      };
    });
  }, [myJumbleRunningWords, myTeam?.words]);

  const isNotSelectedAll =
    orders.length === 0 ||
    orders.filter(i => i !== undefined).length < options.length;

  const isSubmittedAll =
    !isEmpty(myJumbleRunningWords) &&
    (myJumbleRunningWords ?? []).every(i => i.score !== null);

  const notSubmitYet = (myJumbleRunningWords ?? []).every(word => !word.order);

  if (myTeam?.words.some(w => w.status === WORD_STATUSES.TIME_UP)) {
    return <TimesUp />;
  }

  return (
    <KContainer.View alignItems padding="1.5rem">
      <KContainer.View>
        <KContainer.View
          width={SIZE * 1.5}
          height={(SIZE * 1.5) / 1.18}
          br={'4x'}
          justifyContent
          background="#F7F7F7"
          padding={'0.75rem'}
        >
          <KLabel.Text
            color={KColors.primary.normal}
            marginT={'1rem'}
            typo="TextMdNormal"
          >
            {isSubmittedAll
              ? `Your ${
                  myJumbleRunningWords.length === 1 ? 'word has' : 'words have'
                } been submitted, please wait for others ...`
              : 'Look at all the devices in your team to work out the correct order.'}
          </KLabel.Text>

          <KContainer.View flex marginT={'1rem'}>
            <KContainer.View flexG={0} style={{ overflow: 'hidden' }}>
              <div
                style={{
                  overflow: 'scroll',
                  maxHeight: (SIZE * 1.5) / 1.18 - 120
                }}
              >
                {options.map((option, index) => (
                  <KContainer.View row key={`option-${index}`} marginB={'1rem'}>
                    <KContainer.View
                      brW={1}
                      brC="#E2E8F0"
                      paddingV={'0.75rem'}
                      paddingH={'0.25rem'}
                      background={KColors.white}
                      flex={1}
                      br={'4x'}
                    >
                      <KInput.Selection
                        name={`option-${index}`}
                        label={trans('common.answer')}
                        options={option.options}
                        value={orders[index]}
                        onChange={e => {
                          const mOrders = [...orders];
                          mOrders[index] = e.target.value;
                          setOrders(mOrders);
                        }}
                      />
                    </KContainer.View>

                    <KContainer.View width={KSpacingValue['1rem']} />

                    <KContainer.View
                      brW={1}
                      brC="#E2E8F0"
                      paddingV={'0.75rem'}
                      paddingH={'0.25rem'}
                      background={KColors.white}
                      flex={2.5}
                      br={'4x'}
                    >
                      <KLabel.Text>{option.text[0]}</KLabel.Text>
                    </KContainer.View>
                  </KContainer.View>
                ))}
              </div>
            </KContainer.View>
          </KContainer.View>

          {raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id && (
            <InGameRemainingTime remainingTime={time} marginT="1rem" />
          )}
        </KContainer.View>

        <KButton.Solid
          title={
            notSubmitYet ? trans('common.submit') : trans('common.resubmit')
          }
          disabled={!submitButtonEnabled || isNotSelectedAll}
          stretch
          marginT={'1rem'}
          onPress={onSubmit}
          width={'100%'}
        />
      </KContainer.View>
    </KContainer.View>
  );
};

StudentJumble.displayName = 'Containers.InGame.StudentPlaying.Jumble';

export default memo(StudentJumble);
