import {
  EventName,
  IS_TEACHER,
  apiInstance,
  apiInstanceWithoutBaseURL
} from '@constants';
import { BaseResponse } from '@dto';
import { AxiosRequestConfig } from 'axios';
import AppEvent from 'core/event';
import AppStorage, { STORAGE_KEYS } from 'core/storage';
import qs from 'query-string';
import trans from 'translation';
import { UIUtils } from 'utils';
import { v4 as uuidv4 } from 'uuid';

interface IParams {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  url: string;
  configs?: AxiosRequestConfig;
  body?: any;
  showError?: boolean;
  showToast?: boolean;
  noBase?: boolean;
}

apiInstance.interceptors.request.use((config: any) => {
  config.url = config.baseURL + config.url;

  if (!config.headers.Authorization) {
    const localUser = AppStorage.get(STORAGE_KEYS.user);
    if (localUser?.accessToken && IS_TEACHER) {
      config.headers.Authorization = 'Bearer ' + localUser.accessToken;
    }
  }

  config.headers = {
    ...config.headers,
    'X-Request-Id': uuidv4()
  };

  return config;
});

class APIManager {
  static prepareParams = (params: Record<string, any>) => {
    return params ? `?${qs.stringify(params)}` : '';
  };

  static request = async ({
    method = 'GET',
    url,
    configs,
    body,
    showToast = false,
    showError = true,
    noBase = false
  }: IParams): Promise<BaseResponse<any>> => {
    try {
      const instance = noBase ? apiInstanceWithoutBaseURL : apiInstance;
      const res =
        method === 'GET'
          ? await instance[method.toLowerCase()](
              body ? `${url}?${qs.stringify(body)}` : url,
              configs
            )
          : method === 'DELETE'
          ? await instance[method.toLowerCase()](url, {
              ...configs,
              data: body
            })
          : await instance[method.toLowerCase()](url, body, configs);

      if (url.includes('https://oauth2.googleapis.com/token')) {
        return Promise.resolve({
          data: res,
          error: undefined,
          success: true
        });
      }

      if (!res || res.data?.code !== 0) {
        throw {
          ...(res?.data ?? {}),
          code: (res as any).code,
          status: res.status
        };
      } else {
        if (showToast) {
          UIUtils.snackBar.open({
            message: res.data.msg,
            status: 'success'
          });
        }
        return Promise.resolve({
          data: res.data.data,
          error: undefined,
          success: true
        });
      }
    } catch (e: any) {
      const { response, message } = e || {};
      if (!response) {
        // toast.error(message, {
        //   toastId: 'api-status-network'
        // });
        UIUtils.snackBar.open({
          message,
          status: 'danger'
        });

        return Promise.resolve({
          data: undefined,
          error: e,
          success: false,
          eData: undefined
        });
      } else {
        if (response.status === 401) {
          // toast.error(trans('common.session_expired'), {
          //   toastId: 'api-status-401'
          // });
          UIUtils.snackBar.open({
            message: trans('common.session_expired'),
            status: 'danger'
          });

          AppEvent.dispatch(EventName.LOGOUT);
        } else {
          if (showError) {
            // toast.error(
            //   response.data?.Msg ?? trans('common.something_went_wrong'),
            //   {
            //     toastId: `api-status-${response.status || '500'}`
            //   }
            // );
            UIUtils.snackBar.open({
              message:
                response.data?.Msg ??
                response.data?.msg ??
                trans('common.something_went_wrong'),
              status: 'danger'
            });
          }
        }

        return Promise.resolve({
          data: undefined,
          error: e,
          success: false,
          eData: response?.data
        });
      }
    }
  };
}

export default APIManager;
