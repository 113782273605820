import { E_RACE_MODES, WORD_STATUSES } from '@constants/gameConfigs';
import { useGameContext } from 'context/GameContext';
import { useStudentInGameInfo } from 'hooks';
import { useRemainingTimeChanged } from 'hooks/game/useRemainingTimeChanged';
import React, { memo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel } from 'uikit';

import InGameImage from '../InGame.Image';
import InGameRemainingTime from '../InGame.RemainingTime';
import TimesUp from '../TimesUp';

const Supervisor = memo(() => {
  const { onRequestPause } = useGameContext();

  const time = useRemainingTimeChanged();

  const { raceMode, isTaboo } = useGameContext();

  const { opposingTeamRunningWord } = useStudentInGameInfo();

  const banneds = opposingTeamRunningWord?.banneds ?? [];

  return (
    <KContainer.View
      paddingH="1rem"
      paddingV="2rem"
      br="5x"
      background="#F7F7F7"
      margin="1.5rem"
      alignItems="stretch"
    >
      <KLabel.Paragraph marginB="0.75rem" typo="TextMdMedium">
        {trans('common.observing_team_instruction')}
      </KLabel.Paragraph>

      <KContainer.View
        background="#F7F1E2"
        brW={1}
        brC="#CBB235"
        br="x"
        width="100%"
        height={80}
        center
      >
        <KLabel.Text color={KColors.primary.normal} typo="Text3xLgBold">
          {opposingTeamRunningWord?.texts?.[0] ?? ''}
        </KLabel.Text>
      </KContainer.View>

      <KContainer.View
        width="100%"
        height={1}
        marginV="1.5rem"
        background="#C4C4C4"
      />

      {isTaboo && (
        <KContainer.View>
          <KLabel.Text typo="TextMdBold" color="#8B8C9F">
            {trans('common.banned_word_list')}:
          </KLabel.Text>

          <KContainer.View row flexW="wrap" gap="1rem" marginT="1rem">
            {banneds.map(i => (
              <KContainer.View
                key={i}
                paddingH="1.25rem"
                paddingV="0.25rem"
                br="round"
                background="rgb(219,226,236)"
                alignItems="baseline"
              >
                <KLabel.Text typo="TextMdNormal">{i}</KLabel.Text>
              </KContainer.View>
            ))}
          </KContainer.View>
        </KContainer.View>
      )}

      {raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id && (
        <InGameRemainingTime remainingTime={time} marginT="3rem" />
      )}

      <KButton.Solid
        marginT="1.5rem"
        width={'100%'}
        title={trans('common.pause_game')}
        onPress={onRequestPause}
      />
    </KContainer.View>
  );

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage uri="game-on.webp" background="#EFCA2F" />

      <KButton.Solid
        marginT="1.5rem"
        width={'100%'}
        title={trans('common.pause_game')}
        onPress={onRequestPause}
      />
    </KContainer.View>
  );
});

const Guesser = memo(() => {
  const { raceMode } = useGameContext();

  const time = useRemainingTimeChanged();

  return (
    <KContainer.View alignItems padding="1.5rem">
      <InGameImage
        uri="student-guessing.webp"
        background="#358C72"
        justifyContent={
          raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id ? undefined : 'center'
        }
      >
        <KLabel.Paragraph
          typo="TextXLgBold"
          color={KColors.white}
          marginT="1.25rem"
        >
          {trans('common.try_your_best')}
        </KLabel.Paragraph>

        {raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id && (
          <InGameRemainingTime
            remainingTime={time}
            marginT="3rem"
            width="100%"
          />
        )}
      </InGameImage>
    </KContainer.View>
  );
});

const Describer = memo(() => {
  const time = useRemainingTimeChanged();

  const { raceMode, isTaboo } = useGameContext();

  const { myRunningWord } = useStudentInGameInfo();

  const banneds = myRunningWord?.banneds ?? [];

  return (
    <KContainer.View
      paddingH="1rem"
      paddingV="2rem"
      br="5x"
      background="#F7F7F7"
      margin="1.5rem"
      alignItems="stretch"
    >
      <KContainer.View
        background="#F7F1E2"
        brW={1}
        brC="#CBB235"
        br="x"
        width="100%"
        height={80}
        center
      >
        <KLabel.Text color={KColors.primary.normal} typo="Text3xLgBold">
          {myRunningWord?.texts?.[0] ?? ''}
        </KLabel.Text>
      </KContainer.View>

      <KContainer.View
        width="100%"
        height={1}
        marginV="1.5rem"
        background="#C4C4C4"
      />

      {isTaboo && (
        <KContainer.View>
          <KLabel.Text typo="TextMdBold" color="#8B8C9F">
            {trans('common.banned_word_list')}:
          </KLabel.Text>

          <KContainer.View row flexW="wrap" gap="1rem" marginT="1rem">
            {banneds.map(i => (
              <KContainer.View
                key={i}
                paddingH="1.25rem"
                paddingV="0.25rem"
                br="round"
                background="rgb(219,226,236)"
                alignItems="baseline"
              >
                <KLabel.Text typo="TextMdNormal">{i}</KLabel.Text>
              </KContainer.View>
            ))}
          </KContainer.View>
        </KContainer.View>
      )}

      {raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id && (
        <InGameRemainingTime remainingTime={time} marginT="3rem" />
      )}
    </KContainer.View>
  );
});

const Taboo = () => {
  const { isDescriber, isSupervisor, myRunningWord, opposingTeamRunningWord } =
    useStudentInGameInfo();

  if (
    myRunningWord?.status === WORD_STATUSES.TIME_UP ||
    opposingTeamRunningWord?.status === WORD_STATUSES.TIME_UP
  ) {
    return <TimesUp />;
  }

  return isSupervisor ? (
    <Supervisor />
  ) : isDescriber ? (
    <Describer />
  ) : (
    <Guesser />
  );
};

Taboo.displayName = 'Containers.InGame.StudentPlaying.Taboo';

export default memo(Taboo);
