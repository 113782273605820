import { IconAlertCircle } from '@assets/icons/components/IconAlertCircle';
import { IconTrash } from '@assets/icons/components/IconTrash';
import {
  JUMBLE_CONFIGS,
  E_RACE_MODES,
  E_GAME_MODES,
  GAMES
} from '@constants/gameConfigs';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FormItem from 'containers/FormItem';
import Prototype from 'core/prototype';
import { useFetchGame, useResolverForm } from 'hooks';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import trans from 'translation';
import { KButton, KColors, KContainer, KInput, KLabel, KListItem } from 'uikit';

import { IFormData, makeUniqueInputId, onLaunchGame, schema } from './helpers';

const { AVAILABLE_TEAMS, GAME_MODES, NUMBER_OF_STUDENTS, RACE_MODES } =
  JUMBLE_CONFIGS;

interface Props {
  isActive: boolean;
}

const JumbleForm = ({ isActive }: Props) => {
  const { id } = useParams();

  const { data: _game } = useFetchGame(id);

  const game = useMemo(() => {
    return _game?.type === GAMES.JUMBLE ? _game : undefined;
  }, [_game]);

  const methods = useResolverForm<IFormData>({
    schema: schema('JUMBLE'),
    configs: {
      values: {
        id: game?.id,
        folderId: game?.folderId,
        label: game?.label,
        numberOfTeams: game?.numberOfTeams ?? AVAILABLE_TEAMS[0].id,
        numberOfStudents: game?.numberOfStudents ?? NUMBER_OF_STUDENTS[0].id,
        raceMode: game?.raceMode ?? E_RACE_MODES.RACE_NORMAL.id,
        gameMode: game?.gameMode ?? E_GAME_MODES.WORD.id,
        timeLimit: {
          min: game?.min ?? 1,
          sec: game?.sec ?? 0
        },
        words: (
          game?.contents ?? [
            {
              texts: [''],
              banneds: []
            }
          ]
        ).map(i => ({
          keyword: i.texts?.[0] ?? '',
          bannedWords: i.banneds
        })),
        isLaunchGame: true,
        canStudentChangeTeam: false
      }
    }
  });

  const {
    control,
    formState: { errors }
  } = methods;

  const [_gameMode, _raceMode, _words] = useWatch({
    control: control,
    name: ['gameMode', 'raceMode', 'words']
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'words',
    keyName: 'jumbleWordId'
  });

  const timeLimitError = errors.timeLimit?.root?.message;
  const wordsError = errors.words?.root?.message;

  const isWord = _gameMode === E_GAME_MODES.WORD.id;

  const onFormValid = useCallback((data: IFormData) => {
    const { raceMode, gameMode, words, timeLimit, ...rest } = data;

    const mParams = {
      type: GAMES.JUMBLE,
      ...rest,
      ...timeLimit,
      roleTime: raceMode,
      rolePlay: gameMode,
      contents: words.map(w => ({
        texts: [w.keyword],
        banneds: []
      }))
    };

    onLaunchGame(mParams);
  }, []);

  const onMakeUniqueInputId = useCallback((name: string) => {
    return makeUniqueInputId(GAMES.JUMBLE.toLowerCase())(name);
  }, []);

  const renderSettings = useCallback(() => {
    return (
      <>
        <KLabel.Paragraph typo="TextLgNormal" marginV={'1.25rem'} id="test">
          {trans('common.game_settings')}
        </KLabel.Paragraph>

        <KContainer.View marginT="1.25rem">
          <Controller
            name="numberOfTeams"
            control={control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={trans('common.maximum_number_of_teams')}
                  options={AVAILABLE_TEAMS}
                />
              );
            }}
          />
        </KContainer.View>

        <KContainer.View marginT="1.25rem">
          <Controller
            name="numberOfStudents"
            control={control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={trans('common.maximum_member_of_teams')}
                  options={NUMBER_OF_STUDENTS}
                />
              );
            }}
          />
        </KContainer.View>

        <KContainer.View marginT="1.25rem">
          <Controller
            name="raceMode"
            control={control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={
                    <>
                      {trans('common.timing')}

                      <Tooltip title={trans('explanation.timing')}>
                        <KContainer.View size={20} pointer>
                          <IconAlertCircle stroke={KColors.primary.normal} />
                        </KContainer.View>
                      </Tooltip>
                    </>
                  }
                  options={RACE_MODES}
                />
              );
            }}
          />
        </KContainer.View>

        {_raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id && (
          <>
            <FormItem
              label={
                <KContainer.View row alignItems gap="0.25rem">
                  <KLabel.Text typo="TextNmBold">
                    {trans('common.set_limit_time')}
                  </KLabel.Text>

                  <Tooltip title={trans('explanation.set_limit_time')}>
                    <KContainer.View size={20} pointer>
                      <IconAlertCircle stroke={KColors.primary.normal} />
                    </KContainer.View>
                  </Tooltip>
                </KContainer.View>
              }
              jsx={
                <KContainer.View row alignItems>
                  <Controller
                    name="timeLimit.min"
                    control={control}
                    render={({ field }) => {
                      return (
                        <KInput.TextFieldNumber
                          {...field}
                          name={onMakeUniqueInputId(field.name)}
                          placeholder={trans('placeholder.min')}
                          sx={{ width: 100 }}
                        />
                      );
                    }}
                  />

                  <KLabel.Text marginH={'0.75rem'} typo="TextLgMedium">
                    :
                  </KLabel.Text>

                  <Controller
                    name="timeLimit.sec"
                    control={control}
                    render={({ field }) => {
                      return (
                        <KInput.TextFieldNumber
                          {...field}
                          name={onMakeUniqueInputId(field.name)}
                          placeholder={trans('placeholder.sec')}
                          sx={{ width: 100 }}
                        />
                      );
                    }}
                  />
                </KContainer.View>
              }
            />

            {timeLimitError && <KLabel.Error>{timeLimitError}</KLabel.Error>}
          </>
        )}

        <KContainer.View marginT="1.25rem">
          <Controller
            name="gameMode"
            control={control}
            render={({ field }) => {
              return (
                <KInput.Selection
                  {...field}
                  name={onMakeUniqueInputId(field.name)}
                  label={
                    <>
                      {trans('common.game_mode')}

                      <Tooltip
                        title={Prototype.string.markdown(
                          trans('explanation.jumble_game_mode')
                        )}
                      >
                        <KContainer.View size={20} pointer>
                          <IconAlertCircle stroke={KColors.primary.normal} />
                        </KContainer.View>
                      </Tooltip>
                    </>
                  }
                  options={GAME_MODES}
                />
              );
            }}
          />
        </KContainer.View>

        {/* <KContainer.View marginT="1.25rem">
          <Controller
            name="canStudentChangeTeam"
            control={control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={'Students can change team'}
                  checked={field.value}
                />
              );
            }}
          />
        </KContainer.View> */}
      </>
    );
  }, [control, _raceMode, timeLimitError, onMakeUniqueInputId]);

  const renderContents = useCallback(() => {
    return (
      <>
        <KLabel.Paragraph
          typo="TextLgNormal"
          marginB={'1.25rem'}
          marginT={'2rem'}
        >
          {trans('common.game_content')}
        </KLabel.Paragraph>

        {fields.map((d, idx) => {
          return (
            <KContainer.View
              key={d.jumbleWordId}
              marginT={idx === 0 ? 0 : '1.5rem'}
            >
              <KContainer.View row alignItems="flex-end">
                <Controller
                  name={`words.${idx}.keyword`}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        name={onMakeUniqueInputId(field.name)}
                        placeholder={trans('placeholder.keyword')}
                        label={`${
                          GAME_MODES.find(e => e.id === _gameMode)?.title
                        } ${idx + 1}`}
                        multiline={!isWord}
                        minRows={3}
                        message={error?.message}
                      />
                    );
                  }}
                />

                {_words.length > 1 ? (
                  <KContainer.Touchable
                    size={36}
                    center
                    background={KColors.secondary.normal}
                    br="round"
                    marginL="0.75rem"
                    onPress={() => remove(idx)}
                  >
                    <IconTrash
                      width={18}
                      height={18}
                      fill={KColors.white}
                      stroke={KColors.primary.normal}
                    />
                  </KContainer.Touchable>
                ) : (
                  <KContainer.View size={48} />
                )}
              </KContainer.View>
            </KContainer.View>
          );
        })}

        {wordsError && <KLabel.Error>{wordsError}</KLabel.Error>}

        <KButton.Dashed
          title={`+ ${trans('common.add_more')}`}
          marginT={'1.5rem'}
          width={'100%'}
          onPress={() =>
            append({
              keyword: '',
              bannedWords: []
            })
          }
        />

        <Stack direction={'row'} spacing={3} sx={{ marginY: 6 }}>
          <KButton.Solid
            flex
            title={trans('common.launch_game')}
            onPress={e => {
              methods.setValue('isLaunchGame', true);
              methods.handleSubmit(onFormValid)(e);
            }}
          />

          <KButton.Outline
            flex
            title={trans('common.save_to_a_folder')}
            onPress={e => {
              methods.setValue('isLaunchGame', false);
              methods.handleSubmit(onFormValid)(e);
            }}
          />
        </Stack>
      </>
    );
  }, [
    fields,
    wordsError,
    control,
    _words.length,
    onMakeUniqueInputId,
    isWord,
    _gameMode,
    remove,
    append,
    methods,
    onFormValid
  ]);

  return (
    <KContainer.View style={{ display: isActive ? undefined : 'none' }}>
      {renderSettings()}
      {renderContents()}
    </KContainer.View>
  );
};

export default memo(JumbleForm);
