import { IS_TEACHER } from '@constants';
import { ITeamDto } from '@dto';
import { useGameContext } from 'context/GameContext';
import AppSignalR, { SIGNALR_EVENTS } from 'core/signalR';
import { useStudentInGameInfo } from 'hooks';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import trans from 'translation';
import { KButton, KColors, KContainer, KInput, KLabel } from 'uikit';

import { IFormData } from './helpers';
import StudentItem from './StudentItem';

interface IProps {
  item: ITeamDto;
}

const TeamItem = ({ item }: IProps) => {
  const { roomInfo } = useGameContext();

  const { myTeam, myProfile } = useStudentInGameInfo();

  const methods = useFormContext<IFormData>();

  const [teams, users, numberOfStudents, canStudentChangeTeam] = useWatch({
    control: methods.control,
    name: ['teams', 'users', 'numberOfStudents', 'canStudentChangeTeam']
  });

  const { id: teamId, name } = item;

  const index = teams.findIndex(t => t.id === teamId);
  const userIndex = users.findIndex(u => u.id === myProfile?.id);

  // Check condition here:
  // It's false if this student is already in this team or this team is already enough members
  // Otherwise
  const isAbleToJoinTeam = useMemo(() => {
    return (
      !IS_TEACHER &&
      myTeam?.id !== teamId &&
      users.filter(i => i.teamId === teamId).length < numberOfStudents
    );
  }, [myTeam?.id, numberOfStudents, teamId, users]);

  const onSubmit = useCallback(() => {
    AppSignalR.emit(SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS, {
      ...roomInfo,
      teams: (roomInfo?.teams ?? []).map(t => {
        if (t.id === teamId) {
          t.name = name;
        }
        return t;
      })
    });
  }, [name, roomInfo, teamId]);

  const onSwitchTeam = useCallback(() => {
    methods.setValue(`users.${userIndex}.teamId`, teamId);
    AppSignalR.emit(SIGNALR_EVENTS.UPDATE_ROOM_SETTINGS, {
      ...roomInfo,
      users: (roomInfo?.users ?? []).map(i => {
        if (i.id === myProfile?.id) {
          i.teamId = teamId;
        }
        return i;
      })
    });
  }, [methods, myProfile?.id, roomInfo, teamId, userIndex]);

  const teamUsers = useMemo(() => {
    return users.filter(u => u.teamId === teamId);
  }, [teamId, users]);

  return (
    <KContainer.View marginT="1.5rem">
      <KLabel.Paragraph typo="TextNmBold" color={KColors.white}>
        {IS_TEACHER ? `${trans('common.team')} ${index + 1}` : name}
      </KLabel.Paragraph>

      {IS_TEACHER && (
        <KContainer.View marginT="0.75rem" row alignItems>
          <Controller
            name={`teams.${index}.name`}
            control={methods.control}
            render={({ field }) => {
              return <KInput.TextField {...field} placeholder="Name team" />;
            }}
          />

          <KButton.Outline
            title={trans('common.submit')}
            onPress={onSubmit}
            marginL="0.75rem"
          />
        </KContainer.View>
      )}

      {users.map((i, idx) => {
        if (i.teamId === teamId) {
          return (
            <StudentItem
              key={`student-${JSON.stringify(i)}-${teamUsers.length}`}
              item={i}
              index={idx}
              teamIndex={index}
            />
          );
        }

        return null;
      })}

      {!IS_TEACHER && canStudentChangeTeam && (
        <KButton.Dashed
          title={'+ Join team'}
          brC={KColors.white}
          textColor={KColors.white}
          width={'100%'}
          marginT="1rem"
          onPress={onSwitchTeam}
          disabled={!isAbleToJoinTeam}
        />
      )}
    </KContainer.View>
  );
};

export default memo(TeamItem);
