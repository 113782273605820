import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import KInput from './TextField';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KImage from '../Image';
import KLabel from '../Label';
import Loading from '../Loading';

interface IProps extends DropzoneOptions {
  onChange?: (files: any[], onSuccess: (returnedFiles: any[]) => void) => void;
  isLoading?: boolean;
  message?: string;
  defaultFiles?: any[];
  nameTypeFile?: string;
}

const MAX_FILE_SIZE_IN_BYTES = 104857600; // 100MB

const KDropzone = (props: IProps) => {
  const {
    onChange,
    isLoading,
    message: _message,
    nameTypeFile,
    ...rest
  } = props;

  const [files, setFiles] = useState<any[]>([]);
  const [localMessage, setLocalMessage] = useState<string>('');

  const message = useMemo(
    () => _message || localMessage,
    [_message, localMessage]
  );

  const isError = useMemo(() => !!message, [message]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const newFiles = [...files, ...acceptedFiles];
      if (rest.maxFiles && newFiles.length > rest.maxFiles) {
        setLocalMessage(`Max files exceeded: ${rest.maxFiles}`);
        return;
      }
      setFiles(newFiles);
      onChange?.(newFiles, () => {});
      // onChange?.(newFiles, returnedFiles => {
      //   setLocalMessage('');
      //   setFiles(returnedFiles);
      // });
    },
    [files, onChange, rest.maxFiles]
  );

  const onRemove = useCallback(
    (item: any) => {
      const newFiles = files.filter(i => i.id !== item.id);
      setFiles(newFiles);
      onChange?.(newFiles, () => {});
    },
    [files, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    ...rest
  });

  console.log('files', files);

  return (
    <>
      <KContainer.View
        width={'100%'}
        br="x"
        brW={1}
        brS="dashed"
        brC={isError ? KColors.secondary.normal : KColors.blue.normal}
        paddingH="1rem"
        paddingV="0.5rem"
        position="relative"
      >
        <KContainer.View {...getRootProps()}>
          <input {...(getInputProps() as any)} />

          <KContainer.View dp="flex" center width="100%" height="100%" pointer>
            <KContainer.View row alignItems marginB="0.5rem">
              <KImage.MuiIcon
                icon="CloudUploadOutlined"
                color={KColors.blue.normal}
                size={70}
              />

              <KLabel.Text
                marginL="0.5rem"
                color={KColors.blue.normal}
                typo="TextMdNormal"
              >
                UPLOAD FILE{' '}
                {!!nameTypeFile && `(${nameTypeFile.toUpperCase()})`}
              </KLabel.Text>
            </KContainer.View>

            <KLabel.Text color={KColors.blue.normal} typo="TextMdNormal">
              Drag and Drop, or Browse your files
            </KLabel.Text>
          </KContainer.View>

          <KContainer.RenderWhen>
            <KContainer.RenderWhen.If isTrue={files.length > 0}>
              <KContainer.View row alignItems flexW="wrap" marginT="0.5rem">
                {files.map((i, idx) => {
                  return (
                    <KContainer.View key={`file-${idx}`} row alignItems>
                      <KContainer.Touchable
                        row
                        alignItems
                        padding="0.25rem"
                        avoidParentPress
                        onPress={() => {
                          if (i?.id && i?.url) {
                            window.open(i.url, '_blank');
                          }
                        }}
                      >
                        <KImage.MuiIcon
                          icon="AttachFileOutlined"
                          size={24}
                          color="#707277"
                        />

                        <KLabel.Text marginH="0.5rem">
                          {i.name ?? i.fileName}
                        </KLabel.Text>
                      </KContainer.Touchable>

                      <KContainer.Touchable
                        dp="flex"
                        center
                        avoidParentPress
                        onPress={() => onRemove(i)}
                      >
                        <KImage.MuiIcon
                          icon="RemoveCircleOutlineOutlined"
                          size={24}
                          color="#CF0A0A"
                        />
                      </KContainer.Touchable>
                    </KContainer.View>
                  );
                })}
              </KContainer.View>
            </KContainer.RenderWhen.If>
          </KContainer.RenderWhen>
        </KContainer.View>

        {isLoading && <Loading />}
      </KContainer.View>

      <KContainer.RenderWhen>
        <KContainer.RenderWhen.If isTrue={isError}>
          <KContainer.View marginT="0.25rem">
            <KLabel.Error typo="TextXNmNormal">{message}</KLabel.Error>
          </KContainer.View>
        </KContainer.RenderWhen.If>
      </KContainer.RenderWhen>
    </>
  );
};

export default memo(KDropzone);
