import { ENDPOINTS, MUTATION_KEYS } from '@constants';
import { ISendFeedbackParams } from '@request-dto';
import APIManager from '@services';
import { UIUtils } from 'utils';

import { useMutationEnhancer } from '../core';

export const useSendFeedback = () => {
  return useMutationEnhancer<any, ISendFeedbackParams>({
    mutationFn: mParams =>
      APIManager.request({
        url: ENDPOINTS.feedback('Send'),
        method: 'POST',
        body: mParams,
        showToast: true,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      }),
    mutationKey: [MUTATION_KEYS.sendFeedback],
    onSuccess: data => {
      if (data.success) {
        UIUtils.popup.dismiss();
      }
    }
  });
};
