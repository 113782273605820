import { IconFolderSearch } from '@assets/icons/components/IconFolderSearch';
import { MUTATION_KEYS } from '@constants';
import { E_GAME_MODES, E_RACE_MODES, GAMES } from '@constants/gameConfigs';
import loadable from '@loadable/component';
import AppValidation from 'core/validation';
import isNumber from 'lodash/isNumber';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';
import * as yup from 'yup';

const SaveToFolder = loadable(() => import('./components/SaveToFolder'));

export interface IFormDataWord {
  keyword: string;
  bannedWords: string[];
}

export interface IFormData {
  id?: number;
  folderId?: number;
  label?: string;
  numberOfTeams: number;
  numberOfStudents: number;
  raceMode: number;
  gameMode?: number;
  timeLimit: {
    min: number;
    sec: number;
  };
  words: IFormDataWord[];
  isLaunchGame: boolean;
  canStudentChangeTeam: boolean;
}

export const schema = (type?: keyof typeof GAMES) =>
  AppValidation.init({
    timeLimit: yup
      .object()
      .shape({
        min: yup.number(),
        sec: yup.number()
      })
      .test('limit', '', function (v: any) {
        const { raceMode } = this.parent;
        const { min, sec } = v;
        if (
          raceMode === E_RACE_MODES.TIME_LIMIT_WORD.id &&
          isNumber(min) &&
          isNumber(sec)
        ) {
          if (min * 60 + sec > 300) {
            return this.createError({
              message: 'Maximum time limit is 5 mins'
            });
          }
          if (min * 60 + sec < 60) {
            return this.createError({
              message: 'Minimum time limit is 1 min'
            });
          }
        }
        return true;
      }),
    words: yup
      .array()
      .of(
        yup.object().shape({
          keyword: AppValidation.required(trans('validation.required_keyword'))
          // banneds: yup.array()
        })
      )
      .test('minJumbleWords', '', function (v) {
        if (type === 'JUMBLE' && (v ?? []).length < 2) {
          return this.createError({
            message: 'Number of words must be 2 or more'
          });
        }
        return true;
      })
  });

export const makeUniqueInputId = (key: string) => (name: string) => {
  return `${key}.${name}`;
};

export const onLaunchGame = (mParams: any) => {
  const { isLaunchGame } = mParams;

  if (mParams.roleTime === E_RACE_MODES.TIME_LIMIT_WORD.id) {
    const { min, sec } = mParams;
    if (isNumber(min) && isNumber(sec) && min * 60 + sec > 300) {
      UIUtils.snackBar.open({
        message: 'Time limit maximum is 5 mins',
        status: 'danger'
      });
      return;
    }
  }

  let msg = '';

  if (mParams.type === GAMES.TABOO) {
    if (mParams.contents.length % 2 !== 0) {
      msg = 'Number of words must be even';
    } else if (mParams.numberOfStudents > mParams.contents.length / 2) {
      msg =
        'Number of words must be at least double of the number of students in each team';
    }
  } else if (
    [GAMES.HOTSEAT, GAMES.JUMBLE].includes(mParams.type) &&
    mParams.numberOfStudents > mParams.contents.length
  ) {
    msg =
      'Number of words must be at least equal or more number of students in each team';
  }

  if (msg) {
    UIUtils.snackBar.open({
      message: msg,
      status: 'danger'
    });
  } else {
    UIUtils.popup.open({
      withXIcon: true,
      headIcon: {
        jsx: (
          <IconFolderSearch
            stroke={KColors.primary.normal}
            fill={KColors.transparent}
          />
        )
      },
      maxWidth: 'xs',
      title: trans(
        isLaunchGame ? 'common.launch_game' : 'common.save_to_a_folder'
      ),
      content: <SaveToFolder data={mParams} />,
      mutationKey: [MUTATION_KEYS.launchGame]
    });
  }
};
