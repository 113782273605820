import { IS_TEACHER } from '@constants';
import axios from 'axios';
import { dayjs } from 'utils/dayjs';

export const TIMEOUT = 10 * 60 * 1000; // 10 minutes

export const QUERY_KEYS = {
  user: 'queries.user',

  folderList: 'queries.folderList',
  folder: 'queries.folder',
  folderCUD: 'queries.folderCUD',

  gameList: 'queries.gameList',
  gameCUD: 'queries.gameCUD',

  room: 'queries.room',
  activeRoomList: 'queries.activeRoomList'
};

export const MUTATION_KEYS = {
  updateAccount: 'mutations.updateAccount',
  changePassword: 'mutations.changePassword',
  googleVerify: 'mutations.googleVerify',
  googleVerifyWithCode: 'mutations.googleVerifyWithCode',
  resetPassword: 'mutations.resetPassword',

  importGame: 'mutations.importGame',
  cudGame: 'mutations.cudGame',
  launchGame: 'mutations.launchGame',
  moveGame: 'mutations.moveGame',
  duplicateGame: 'mutations.duplicateGame',

  terminateRoom: 'mutations.terminateRoom',

  sendFeedback: 'mutations.sendFeedback'
};

export const COMMON_HEADERS = {
  responseType: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Zone-Id': dayjs.tz.guess()
};

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: COMMON_HEADERS,
  timeout: TIMEOUT
});

export const apiInstanceWithoutBaseURL = axios.create({
  baseURL: '',
  headers: COMMON_HEADERS,
  timeout: TIMEOUT
});

export const updateAuthorizationToken = (token?: string) => {
  if (!IS_TEACHER) {
    return;
  }
  if (token) {
    apiInstance.defaults.headers.common.Authorization = 'Bearer ' + token;
  } else {
    apiInstance.defaults.headers.common.Authorization = '';
  }
};

const generateServiceEndpoint = (
  service: string,
  path: string,
  version = 1
) => {
  const _path = path ? `${service}/${path}` : service;

  return `/v${version}/${_path}`;
};

export const ENDPOINTS = {
  account: (path: string = '', version = 1) =>
    generateServiceEndpoint('account', path, version),

  auth: (path: string = '', version = 1) =>
    generateServiceEndpoint('authentication', path, version),

  folder: (path: string = '', version = 1) =>
    generateServiceEndpoint('folder', path, version),

  gameContent: (path: string = '', version = 1) =>
    generateServiceEndpoint('game-content', path, version),

  gameSetup: (path: string = '', version = 1) =>
    generateServiceEndpoint('game-setup', path, version),

  gameRoom: (path: string = '', version = 1) =>
    generateServiceEndpoint('game-room', path, version),

  feedback: (path: string = '', version = 1) =>
    generateServiceEndpoint('feedback', path, version)
};
