import validation from 'core/validation';
import { useSendFeedback } from 'hooks';
import { useResolverForm } from 'hooks/core/useResolverForm';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import trans from 'translation';
import { KButton, KContainer, KForm, KInput, KLabel } from 'uikit';
import { UIUtils } from 'utils';
import * as yup from 'yup';

interface IFormData {
  title: string;
  content: string;
  attachments: any[];
}

const Feedback = () => {
  const { mutate, isPending } = useSendFeedback();

  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({
      title: validation.required(),
      content: validation.required()
    }),
    configs: {
      defaultValues: {
        title: '',
        content: '',
        attachments: []
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = new FormData();
      mParams.set('title', data.title);
      mParams.set('content', data.content);

      data.attachments.forEach((f, idx) => {
        mParams.append(`files[${idx}]`, f); // Use a unique key for each file
      });

      mutate(data);
    },
    [mutate]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KContainer.View>
        <KLabel.Paragraph typo="TextNmMedium">
          {trans('common.feedback_message')}
        </KLabel.Paragraph>
      </KContainer.View>

      <KContainer.View marginT="1rem">
        <Controller
          name="title"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.TextField
                {...field}
                label={trans('common.title')}
                required
                message={error?.message}
              />
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="1rem">
        <Controller
          name="content"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.TextField
                {...field}
                label={trans('common.content')}
                multiline
                rows={4}
                required
                message={error?.message}
              />
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="1rem">
        <Controller
          name="attachments"
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.Dropzone
                {...field}
                // isLoading={uploadLoading}
                message={error?.message}
              />
              // <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
              //   {({ getRootProps, getInputProps }) => (
              //     <section>
              //       <div {...getRootProps()}>
              //         <input {...getInputProps()} />
              //         <p>
              //           Drag and drop some files here, or click to select files
              //         </p>
              //       </div>
              //     </section>
              //   )}
              // </Dropzone>
            );
          }}
        />
      </KContainer.View>

      <KContainer.View marginT="1.75rem" row alignItems gap="1.5rem">
        <KButton.Outline
          flex
          title={trans('common.cancel')}
          onPress={() => UIUtils.popup.dismiss()}
          disabled={isPending}
        />

        <KButton.Solid
          flex
          title={trans('common.send')}
          type="submit"
          // disabled={!methods.formState.isValid}
          isLoading={isPending}
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(Feedback);
